import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../components/common/Layout'
import Banner from '../components/index/Banner'
import Section1 from '../components/index/Section1'
import Section2 from '../components/index/Section2'
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby';

const IndexPage = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>OKR Board - Create your own OKR Board</title>
        <link rel="shortcut icon" href="../images/favicon.jpg" />
      </Helmet>
      <Layout>
        {/* <SEO title="Home" keywords={['gatsby', 'application', 'react']} /> */}
        <Banner isMobile={false} mainImage={data.file.childImageSharp.fixed} />
        <Section1 isMobile={false} />
        <Section2 />
      </Layout>
    </>
  )
}

const IndexPageQuery = props => (
  <StaticQuery
    query={graphql`
            query Index3Page {
                file(relativePath: {eq: "dashboard.png"}) {
                    childImageSharp {
                        fixed(width:900) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
    render={data => <IndexPage data={data} {...props} />}
  />
)

export default IndexPageQuery
