import React from 'react'
import { Row, Col, Button } from 'antd'
import { Link } from 'gatsby';
// import ''
function Section1() {
    const contents = [
        {
            "img": "simple",
            "title": "Simple",
            "description": "Overly simplified for small and medium scale businesses to sky rocket their company growth."
        },
        {
            "img": "measurable",
            "title": "Measurable",
            "description": "Keep track of the progress of company wide objectives and key results as well as of the team and individuals."
        },
        {
            "img": "on_point",
            "title": "On Point",
            "description": "You will only see what is important to you so that you can say NO to everything else. Focus on stuff that truly matters. "
        }
    ]
    return (
        <>
            <div className='sec1'>
                <div className="home-page-wrapper" id="page1-wrapper">
                    <h2>Benefits of using <span>OKR Board</span></h2>
                    <div className='block-wrapper'>
                        <Row gutter={48} type="flex">
                            {
                                contents.map((content, index) => (
                                    <Col key={index} span={8} className="contentCol">
                                        <div className='icon'>
                                            <img src={require('../images/' + content.img + '.svg')} width='100%' />
                                        </div>
                                        <h3><span>{content.title}</span></h3>
                                        <div><span>{content.description}</span></div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </div>
            </div>
            <div className='content1-section1'>
                <div className='home-page-wrapper content1-wrapper'>
                    <Row gutter={48} type="flex">
                        <Col className='content1-img' span={8}><img src={require('./grow.svg')} width='100%' alt='img' /></Col>
                        <Col className='content1-content' span={16} >
                            <h2>What is OKR?</h2>
                            <div className='description'>
                                <span>
                                    OKR stands for Objectives and Key Results. An Objective is what you want to accomplish, and a Key Result is how you plan to accomplish it.
                                </span>
                                <span>
                                    An objective requires a great deal of thinking, it should be a milestone that tests your limits and is ambitious for your company.Key results are a set of quantitative and achievable metrics that define the success of an objective.
                                </span>
                                <span className='okr-learn-more-button'><Link to='/what-is-okr'><Button type='primary'>Learn More</Button></Link></span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Section1
