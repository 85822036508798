import React from 'react'
function Section2() {
    const tabs = [
        {
            'desc': [
                {
                    'title': 'Transparency',
                    'content': 'OKR ensures that managers and employees are updated about the company’s progress, this ensures productive cross-function interdependencies.'
                },
                {
                    'title': 'Stretch Goals',
                    'content': 'The strategic implementation of the stretch goals in OKRs allows the employees to push their limits.'
                },
                {
                    'title': 'Engagement',
                    'content': 'Employee engagement can produce remarkable outcome if they are engaged with a purpose. OKR enhances the engagement to execute the strategies.'
                }
            ],
            // 'src': ''
        }
    ]
    return (
        <>
            <div className='sec2 section2 content0'>
                <div className="home-page-wrapper content0">
                    <h2>How is OKR a roadmap to your success?</h2>
                    <div className='content'>
                        Structuring a roadmap to success is crucial for your business.
                    </div>
                </div>
                {
                    tabs.map((data, index) => (
                        <div key={index} className='tabContent'>
                            <div className='tabData'>
                                {
                                    data.desc.map((ds, index) => (
                                        <span key={index}>
                                            <h3>{ds.title}</h3>
                                            <p>{ds.content}</p>
                                            <br />
                                        </span>
                                    ))
                                }

                            </div>
                            <div className='tabImg'>
                                <img src={require('./Group.svg')} width='100%' alt='img' />
                            </div>
                        </div>
                    ))
                }
            </div>

        </>
    )
}

export default Section2