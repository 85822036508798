import React, { useState } from 'react'
import swal from 'sweetalert';
// import Text from 'antd/lib/typography/Text'
import { Button, Input, Modal, Icon, Tooltip } from 'antd'
import PreRegPopupForm from './PreRegPopupForm';
import Img from 'gatsby-image'
// import Measure from 'react-measure'
// const Search = Input.Search
export default function Banner(props) {
  return (
    <div className='bnr-wrapper'>
      <div className='bnr'>
        <div className='bnr-title-wrapper'>
          <h1>
            Accomplish organizational goals faster
          </h1>
        </div>
        <div className='bnr-content'>
          <p>OKR Board provides a structured way to plan, execute and track your business objectives</p>
        </div>
        <div className='register-btn'>
          <PreRegPopupForm btnText='Register' type='primary' size='large' />
        </div>
      </div>
      <div className='bnr-img'>
        <Img fixed={props.mainImage} alt="banner" />
        {/* <img src={require('./dashboard.png')} alt='img' /> */}
      </div>

    </div>
  )
}
